.parent-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 3fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.bg-image {
  background-image: url("https://www.obat.fr/blog/wp-content/uploads/2022/03/marche-prive.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.see-more {
  background-color: #2EAB82;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  margin: 0px 0px 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: "#000";
  cursor: pointer;
}

.see-more:hover {
  background-color: #2EAB82;
  color: #fff;
}