.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

.cf {
  zoom: 1;
}

.search-wrapper {
  width: 100%;
  /* border-radius: 40px; */
  background: transparent;
  display: flex;
}

.search-wrapper input {
  padding: 10px 5px;
  float: left;
  font-size: 10pt;
  border: 0;
  background: #fff;
  /* border-radius: 5px; */
  border-top-style: none;
  flex: 1;
  color: #000;
}

.search-wrapper input:focus {
  outline: 0;
  background: #fff;
}

.search-wrapper input::-webkit-input-placeholder {
  color: #999;
  font-weight: normal;
  font-style: italic;
  padding-left: 20px;
}

.search-wrapper input:-moz-placeholder {
  color: #999;
  font-weight: normal;
  font-style: italic;
}

.search-wrapper input:-ms-input-placeholder {
  color: #999;
  font-weight: normal;
  font-style: italic;
  border-style: none;
}

.search-wrapper .button {
  overflow: visible;
  position: relative;
  float: right;
  border: 0;
  padding: 0;
  cursor: pointer;
  padding: 0px 10px;
  font-size: 10pt;
  color: #000;
  text-transform: uppercase;
  background: #ffcc00;
  /* border-radius: 5px; */
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-wrapper .button:hover {
  /*     background: #e54040; */
}

.search-wrapper .button:active,
.search-wrapper .button:focus {
  background: #ffcc00;
  outline: 0;
}

.search-wrapper .button:focus:before,
.search-wrapper .button:active:before {
  border-right-color: #c42f2f;
}

.search-wrapper .button::-moz-focus-inner {
  /* remove extra .button spacing for Mozilla Firefox */
  border: 0;
  padding: 0;
}
