.tooltip-dashboard {
  position: absolute;
  top: 5%;
  left: 98%;
  background-color: #fff;
  color: #000;
  padding: 4px 4px;
  border-radius: 4px;
  z-index: 999;
  min-width: 200px;
  box-shadow: 1px 3px 8px -1px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: 1px 3px 8px -1px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: 1px 3px 8px -1px rgba(255, 255, 255, 0.75);
  border: 1px solid rgb(240, 240, 240);
}

.item {
  padding: 10px 5px;
  font-size: 10pt;
  cursor: pointer;
  border-radius: 7px;
}

.item:hover {
  background-color: rgba(46, 171, 130, 1);
  color: #fff;
}
