/* The Modal (background) */
.modal {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9998; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  color: black;
}

/* Modal Content/Box */
.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  border: 1px solid #888;
  width: 50%;
  border-radius: 5px;
}

/* The Close Button */
.close {
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-data {
  /* background-color: blue; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 4fr 0.5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
}

.footer-modal {
    border-top: 1px solid #DFDFDF;
    display: flex;
    justify-content: flex-end;
}
.header-modal {
    border-bottom: 1px solid #DFDFDF;
    display: flex;
    align-items: center;
}

.modal-title {
  font-size: 12pt;
  font-weight: bold;
  margin-left: 10px;
}

.close-button {
  display: flex;
  justify-content: flex-end;
  margin-top: -17px;
  margin-right: -15px;
  cursor: pointer;
  color: #FFCC00;
}

.close-button:hover {
  color: #b4370e;
}