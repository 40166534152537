.dropbtn {
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 15vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content small {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 10pt;
  cursor: pointer;
  border-bottom: 1px solid #F0F0EE;
}

.dropdown-content small:hover {
  background-color: #2EAB82;
  color: #fff
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

@media only screen and (max-width: 768px) {
  .dropdown:focus:not(:focus-visible) .dropdown-content {
    display: none;
  }
}