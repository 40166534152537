
:root {
  --color-bg: #EEEDEB;
  --color-title: #0E1C4E;
  --color-summary-1: #EBFEF5;
  --color-summary-1-highlight: #FFCC00;
  --font-ibm-plex-sans: "IBM Plex Sans", sans-serif;
}

.card-collapse {
  background: transparent;
}
.card-collapse h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 12pt;
  /* line-height: 1.2; */
  color: var(--color-title);
  text-align: center;
  /* margin-bottom: 20px; */
}
.card-collapse details {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
  border-left: 15px solid gray;
}

.card-collapse details.option {
  --highlight: var(--color-summary-1-highlight) ;
  background: var(--color-summary-1);
  /* border-left-color: var(--color-summary-1-highlight); */
}
.card-collapse details.option p {
  list-style-type: corona-option;
}
.card-collapse details.info {
  --highlight: var(--color-summary-2-highlight) ;
  background: var(--color-summary-2);
  border-left-color: var(--color-summary-2-highlight);
}

.card-collapse details summary, .card-collapse details p {
  position: relative;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 10pt;
  color: var(--color-title);
  padding: 10px;
  cursor: pointer;
}
.card-collapse details summary::-webkit-details-marker, .card-collapse details p::-webkit-details-marker {
  display: none;
}
.card-collapse details summary:focus, .card-collapse details p:focus {
  outline: solid 3px var(--highlight);
}
.card-collapse details summary::-moz-selection, .card-collapse details p::-moz-selection {
  background-color: var(--highlight);
}
.card-collapse details summary::selection, .card-collapse details p::selection {
  background-color: var(--highlight);
}
.card-collapse details p {
  display: list-item;
  cursor: pointer;
}
.card-collapse details p:hover {
    background-color: #FFCC00;
    color: white;
}
.card-collapse details summary::before {
  cursor: pointer;
  position: absolute;
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  left: 0rem;
  margin-right: 0.5rem;
  content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.6066 12H1.3934' stroke='%23202842' stroke-width='1.875' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 1.39343V22.6066' stroke='%23202842' stroke-width='1.875' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.card-collapse details[open] summary {
  font-weight: 700;
}
.card-collapse details[open] summary::before {
  transform: rotate(45deg);
  content: url("data:image/svg+xml,%3Csvg width='100%' height='100%' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.6066 12H1.3934' stroke='%23202842' stroke-width='3.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 1.39343V22.6066' stroke='%23202842' stroke-width='3.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}