@import url("https://fonts.googleapis.com/css2?family=Spline+Sans:wght@300;400;500;600;700&display=swap");
*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --c-white: #fff;
  --c-black: #000;
  --c-ash: #eaeef6;
  --c-charcoal: #a0a0a0;
  --c-void: #141b22;
  --c-fair-yellow: #FFD100;
  --c-apricot: #2EAB82;
  --c-coffee: #754D42;
  --c-del-rio: #917072;
  --c-java: #1FCAC5;
  --c-titan-white: #f1eeff;
  --c-cold-purple: #7EBAA6;
  --c-indigo: #2EAB82;
  --c-governor: #2EAB82;
}

/* body {
  font-family: "Spline Sans", sans-serif;
  line-height: 1.5;
  min-height: 100vh;
  background-color: var(--c-ash);
} */

.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  width: 90%;
  /* max-width: 1000px; */
  margin: 5vh auto;
  
}

.card {
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(65, 51, 183, 0.25);
  max-width: 300px;
}

.information {
  background-color: var(--c-white);
  padding: 1.5rem;
}
.information .tag {
  display: inline-block;
  background-color: rgba(37, 137, 104, 0.1);
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0.5em 0.75em;
  line-height: 1;
  border-radius: 6px;
}
.information .tag + * {
  margin-top: 1rem;
}
.information .title {
  font-size: 1.5rem;
  color: var(--c-void);
  line-height: 1.25;
}
.information .title + * {
  margin-top: 1rem;
}
.information .info {
  color: var(--c-charcoal);
}
.information .info + * {
  margin-top: 1.25rem;
}
.information .button {
  font: inherit;
  line-height: 1;
  background-color: var(--c-white);
  border: 2px solid var(--c-indigo);
  color: var(--c-indigo);
  padding: 0.5em 1em;
  border-radius: 6px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}
.information .button:hover, .information .button:focus {
  background-color: var(--c-indigo);
  color: var(--c-white);
}
 .details {
  display: flex;
  gap: 1rem;
}
 .details div {
  padding: 0.75em 1em;
  background-color: var(--c-titan-white);
  border-radius: 8px;
  display: flex;
  flex-direction: column-reverse;
  gap: 0.125em;
  flex-basis: 50%;
}
 .details dt {
  font-size: 0.875rem;
  color: var(--c-cold-purple);
}
 .details dd {
  color: var(--c-indigo);
  font-weight: 600;
  font-size: 1.25rem;
}

.plan {
  padding: 10px;
  background-color: var(--c-white);
  /* color: var(--c-del-rio); */
}
.plan strong {
  font-weight: 600;
  color: var(--c-coffee);
}
.plan .inner {
  padding: 20px;
  padding-top: 40px;
  background-color: var(--c-fair-yellow);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}
.plan .pricing {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--c-apricot);
  border-radius: 99em 0 0 99em;
  display: flex;
  align-items: center;
  padding: 0.625em 0.75em;
  font-size: 12pt;
  font-weight: 600;
  color: #FFF;
}
.plan .pricing small {
  color: var(--c-del-rio);
  font-size: 0.75em;
  margin-left: 0.25em;
}
.plan .title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #000;
}
.plan .title + * {
  margin-top: 0.75rem;
}
.plan .info + * {
  margin-top: 1rem;
}
.plan .features {
  display: flex;
  flex-direction: column;
}
.plan .features li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.plan .features li + * {
  margin-top: 0.75rem;
}
.plan .features .icon {
  background-color: var(--c-java);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--c-white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.plan .features .icon svg {
  width: 14px;
  height: 14px;
}
.plan .features + * {
  margin-top: 1.25rem;
}
.plan button {
  font: inherit;
  background-color: var(--c-indigo);
  border-radius: 6px;
  color: var(--c-white);
  font-weight: 500;
  font-size: 1.125rem;
  width: 100%;
  border: 0;
  padding: 1em;
}
.plan button:hover, .plan button:focus {
  background-color: var(--c-governor);
}