@import url("https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i");
.patterns {
  /* height: 100vh; */
}

svg text {
  font-family: Lora;
  letter-spacing: 2px;
  stroke: #FFF;
  font-size: 25pt;
  font-weight: bold;
  stroke-width: 1;
  animation: textAnimate 5s infinite alternate;
}

@keyframes textAnimate {
  0% {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
    fill: hsl(0, 0%, 100%);
  }

  100% {
    stroke-dasharray: 50% 0;
    stroke-dashoffstet: -20%;
    fill: hsla(0, 0%, 86%, 0);
  }
}

.flash {
  animation-duration: 2s;
  animation-name: flash;
  animation-iteration-count: infinite;
  transition: none;
  color: #000;
  font-size: 12pt;
  background-color: #FFCC00;
  padding: 0px 5px;
  border-radius: 25px;
}
@keyframes flash {
  0% {
    color: #000000;
  }
  40% {
    color: #FFCC00;
  }
  100% {
    opacity: #000000;
  }
}

.flash-card {
  animation-duration: 2s;
  animation-name: flash-card;
  animation-iteration-count: infinite;
  transition: none;
  color: #FFF;
  font-size: 12pt;
  background-color: transparent;
  padding: 0px 5px;
  border-radius: 25px;
}
@keyframes flash-card {
  0% {
    color: #FFF;
  }
  40% {
    color: transparent;
  }
  100% {
    opacity: #FFF;
  }
}
