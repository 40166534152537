.animate-me {
  opacity: 0; /* Initially hide the div */
}

/* Define animation */
@keyframes slideIn {
  from {
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(50px); /* Start with 50px translation */
  }
  to {
    opacity: 1; /* End with opacity 1 */
    transform: translateY(0); /* End with no translation */
  }
}

/* Apply animation when div appears */
.animate-me {
  animation: slideIn 1s ease forwards; /* 1 second animation */
}

/* body {
  background: #000;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
.btn-shine {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  padding: 5px;
  color: #fff;
  background: linear-gradient(to right, #000 0, #fff 10%, #000 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 12pt;
  text-decoration: none;
  white-space: nowrap;
}
@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-50px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-20px); }
}

