/* body {
  align-items: center;
  background: #e3e3e3;
  display: flex;
  height: 100vh;
  justify-content: center;
} */

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  background: white;
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  /* margin: auto; */
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /* width: 95%; */
}
.slider::before,
.slider::after {
  /* background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
/* .slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
} */
.slider .slide-track {
  -webkit-animation: scroll 28s linear infinite;
  animation: scroll 28s linear infinite;
  display: flex;
  width: calc(250px * 10);
  align-items: center;
  justify-content: center;
}
.slider .slide {
  height: 100px;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}
