.form-container {
  padding: 10px;
}

.input-text {
  width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  font-size: 10pt;
}

.input-select {
  width: 100%;
  padding: 12px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  font-size: 10pt;
}

.input-image {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.input-select:focus {
  outline: none !important;
  border: 2px solid #2EAB82;
}

.input-text:focus {
  outline: none !important;
  border: 2px solid #2EAB82;
}

.parent-two-items-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.label {
  font-size: 8pt;
  margin-left: 5px;
  margin-top: 5px;
  color: #8b8b8b;
}