/* :root {
  --arrow-bg: rgba(46,171,130, 0.7);
  --arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
  --option-bg: rgba(46,171,130, 0.2);
  --select-bg: rgba(46,171,130, 0.2);
} */

body {
  font-family: "Poppins", sans-serif;
  background-color: #258968;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title-margin {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}

.button-18 {
  align-items: center;
  background-color: #0a66c2;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  /* font-family: -apple-system, system-ui, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Fira Sans", Ubuntu, Oxygen, "Oxygen Sans", Cantarell, "Droid Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Lucida Grande", Helvetica, Arial, sans-serif; */
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 20px;
  max-width: 480px;
  min-height: 40px;
  min-width: 0px;
  overflow: hidden;
  padding: 0px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  touch-action: manipulation;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s,
    color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
}

.button-18:hover,
.button-18:focus {
  background-color: #16437e;
  color: #ffffff;
}

.button-18:active {
  background: #09223b;
  color: rgb(255, 255, 255, 0.7);
}

.button-18:disabled {
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.3);
}

.elevation {
  -webkit-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

a {
  color: inherit;
  text-decoration: none;
}

.text-info {
  color: #000;
  font-size: 12pt;
}

.tag-green {
  border: 1px solid rgba(37, 137, 104);
}

li {
  margin-bottom: 25px;
}

.date-news {
  font-size: 7pt;
  color: #000;
  text-align: left;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  top: 10px;
  left: 0px;
  width: 15vw;
  background-color: #258968;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  font-size: 8pt;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.pubs {
  color: #000;
  font-size: 9pt;
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 55px;
}

.pagination small {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.side-pagination:hover {
  background-color: #2eab82;
  color: #fff;
}

.side-pagination-disabled:hover {
  background-color: #cdcdcd;
  color: #fff;
}

/* HTML: <div className="loader"></div> */

.full-screen-loader {
  background-color: #cbfbe6;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  background: radial-gradient(circle closest-side, #ffcc00 94%, #0000)
    right/calc(200% - 1em) 100%;
  animation: l24 1s infinite alternate linear;
}
.loader::before {
  content: "Les Marchés...";
  line-height: 1em;
  color: #258968;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 94%, #258968);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: "Poppins", sans-serif;
}

.spinner-loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid #cbfbe6;
  border-right-color: #ffcc00;
  animation: l2 1s infinite linear;
}

.in-container-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@keyframes l24 {
  100% {
    background-position: left;
  }
}

.slider-home {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slide-home {
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  transition: opacity 0.5s ease; /* Smooth transition effect */
  opacity: 0; /* Initially hide all slides */
}

.slide-home.active {
  opacity: 1; /* Display the active slide */
}

@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}

.home-right-side {
  background-image: url("https://i.pinimg.com/originals/9e/69/33/9e6933cf9b494515ab6166fd5dc6cf61.jpg");
}

.home-tag {
  color: #000;

  padding: 5px;
  font-weight: bold;
}

.three h1 {
  /* font-size: 28px; */
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 15px;
  position: relative;
  margin-top: 1em;
}
.three h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #2eab82;
}
.three h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #2eab82;
}

/* .custom-select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   border: 0;
   outline: 0;
   font: inherit;
   width: 20rem;
   padding: 0.6rem 4rem 0.6rem 1rem;
   background: var(--arrow-icon) no-repeat right 0.8em center/1.4em,
     linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
   color: black;
   border-radius: 0.25em;
   box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
   cursor: pointer;
   font-size: 12pt;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:focus {
  outline: none;
}
.custom-select option {
  color: inherit;
  background-color: var(--option-bg);
}

.custom-select option:focus {
  box-shadow: 0 0 10px 100px red inset !important;
  color:white;
} */

body.modal-open {
  overflow: hidden;
}

#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #ffcc00;
  color: #000;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  left: 45%;
  bottom: 30px;
  font-size: 17px;
  z-index: 9999;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.newsletter-registered {
  background-color: #cbfbe6;
  border-radius: 50px;
  padding: 10px;
  font-size: 13pt;
  color: #127d5a;
  text-align: center;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

.cible {
  font-size: 16pt;
  font-weight: normal;
  /* background-color: #FFCC00; */
  color: #fff;
  margin-top: 15px;
}


.cursor{
  position: relative;
  width: 24em;
  margin: 0 auto;
  border-right: 2px solid rgba(255,255,255,.75);
  font-size: 13pt;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);    
}
/* Animation */
.typewriter-animation {
animation: 
  typewriter 5s steps(50) 1s infinite normal both, 
  blinkingCursor 500ms steps(50) infinite normal;
}
@keyframes typewriter {
from { width: 0; }
to { width: 100%; }
}
@keyframes blinkingCursor{
from { border-right-color: rgba(255,255,255,.75); }
to { border-right-color: transparent; }
}